import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import "@fortawesome/fontawesome-free/css/all.min.css"
import { getMobileOperatingSystem } from "./app"

const IndexPage = ({ data }) => {
  const [isMobile, setIsMobile] = useState(true)
  const { allMarkdownRemark } = data
  const { html } = allMarkdownRemark.nodes.find(
    item => item.html.includes("Ninja - نينجا Account Deletion")
  )

  useEffect(() => {
    const OS = getMobileOperatingSystem()
    setIsMobile(OS === "Android" || OS === "iOS")
  }, [])
  return (
    <div
      style={{
        paddingTop: `${isMobile ? "3%" : "10vh"}`,
        paddingBottom: "20vh",
        paddingLeft: `calc(${
          isMobile ? "5%" : "20%"
        } + env(safe-area-inset-left))`,
        paddingRight: `calc(${
          isMobile ? "5%" : "20%"
        } + env(safe-area-inset-right))`,
      }}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  )
}

export default IndexPage

export const query = graphql`
  query AccountDeletionQuery {
    allMarkdownRemark {
      nodes {
        html
      }
    }
  }
`
